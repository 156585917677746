import store from "@/store/store";

export default function() {
  function closeOverlay() {
    store.commit("setOverlayState", false);
  }

  function openOverlay() {
    store.commit("setOverlayState", true);
  }

  return { closeOverlay, openOverlay };
}
