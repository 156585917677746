<template>
  <div class="appbutton" ref="appbutton">
    <router-link v-if="routerlink" :to="getRouterPath()" class="footer__a">
      <div class="appbutton__text">
        <slot></slot>
      </div>
    </router-link>
    <div v-else class="appbutton__text">
      <slot></slot>
    </div>
    <div class="appbutton__marker"></div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";

import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: { routerlink: String, link: String },
  setup(props) {
    const { prefixPath } = getPathFunction();
    function getRouterPath() {
      if (props.routerlink) {
        return prefixPath(props.routerlink);
      } else {
        return "";
      }
    }
    const appbutton = ref(null);

    const randomNumb = ref(null);
    onMounted(() => {
      if (props.link) {
        appbutton.value.addEventListener("click", openNewTab);
      }

      function openNewTab(e) {
        e.stopPropagation();
        e.preventDefault();
        randomNumb.value = "?" + Math.floor(Math.random() * 10000000000000000);
        window.open(props.link + randomNumb.value, "_blank");
      }
    });
    return { appbutton, randomNumb, getRouterPath };
  },
};
</script>
