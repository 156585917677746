<template>
  <div
    id="navmenubtn"
    ref="navmenubtnIcon"
    :class="isMenuOpen ? 'open' : ''"
    class="no-touch-out"
    @click.prevent="toggelMenu"
  >
    <div class="navmenubtn__icon">
      <span id="s_1"></span>
      <span id="s_2"></span>
      <span id="s_3"></span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";

import gsap from "gsap";
export default {
  setup() {
    const store = useStore();
    const navmenubtnIcon = ref(null);
    const isMenuOpen = computed(() => {
      return store.state.isMenuOpen;
    });

    function toggelMenu() {
      if (isMenuOpen.value) {
        closeMenu();
      } else {
        openMenu();
      }
    }
    function closeMenu() {
      store.commit("setMenuState", false);
    }
    function openMenu() {
      store.commit("setMenuState", true);
    }
    onMounted(() => {
      gsap.to("#navmenubtn", { opacity: 1, duration: 1, delay: 0 });
    });
    return { isMenuOpen, closeMenu, openMenu, toggelMenu, navmenubtnIcon };
  },
};
</script>

<style lang="scss" scoped>
#navmenubtn {
  position: relative;
  width: 30px;
  height: 20px;
  opacity: 0;
}
.t-menu {
  font-size: 80px;
}

.navmenubtn__icon {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  transition: all 200ms 200ms ease-in-out;
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.navmenubtn__icon span {
  position: absolute;
  width: 30px;
  height: 4px;
  background-color: white;
  transition: all 200ms;
}

// animation
.open .navmenubtn__icon {
  transform: rotate(45deg);

  & span {
    background-color: black;
  }
}

.open #s_1 {
  top: 8px;
  left: 0;
  transform: rotate(-90deg);
}

.open #s_3 {
  width: 0;
}

.menu-content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  align-items: center;
  overflow: hidden;
  transform: translate(-100%, 0);
}

.menu.open > .menu-content {
  width: 100vw;
  height: 100vh;
}

#s_1 {
  top: 0;
  left: 0;
}

#s_2 {
  top: 8px;
  left: 0;
}

#s_3 {
  top: 16px;
}
</style>
