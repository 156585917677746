<template>
  <div class="thelogosmall">
<svg version="1.1" id="thelogosmall" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 74.9 80.2" style="enable-background:new 0 0 74.9 80.2;" xml:space="preserve">

<path class="thelogosmall__color" d="M72.1,59c-2.4-1.2-4-0.5-4.8,0c-1.4,1-1.9,2.7-1.8,4c0.1,1.1,0.7,2,1.6,2.4c0.9,0.4,2-0.1,2.4-1
	c0.3-0.7,0.1-1.4-0.4-1.9c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.5-0.1,1,0.2c0.7,0.5,1.5,4.5-0.1,8.8c-0.8,2.3-2.8,6-7.2,5.5
	c-4.7-0.7-5.1-4.6-5.1-8.6c0-5.9,5-12.4,8.7-18.9c2.4-4.2,5.8-8.6,6.6-11.3c1.7-6.4-4-12.7-7.2-17c-0.6-0.9-1.3-1.7-1.8-2.5
	c-2-3-0.3-11.2,1.2-15.7c0.3-0.9-0.2-2-1.1-2.3c-0.9-0.3-2,0.2-2.3,1.1c-0.8,2.2-1,5.7-2.5,8c-2.1,3-5.9,4.8-8,5.3
	c-8.3,1.4-12.1,6-14.1,10c-2-4-5.8-8.7-14.1-10.1c-2.1-0.5-5.9-2.3-8-5.3c-1.6-2.3-1.8-5.8-2.5-8c-0.3-0.9-1.4-1.5-2.3-1.1
	C9.7,0.4,9.2,1.5,9.5,2.4c1.5,4.5,3.2,12.7,1.2,15.7c-0.5,0.8-1.2,1.6-1.8,2.5c-3.2,4.4-9,10.7-7.3,17c0.7,2.7,4.2,7.1,6.5,11.3
	c3.7,6.5,8.7,13,8.7,18.9c0,4-0.4,7.9-5.1,8.6c-4.4,0.5-6.4-3.2-7.2-5.5c-1.5-4.3-0.8-8.3,0-8.8c0.6-0.3,0.9-0.3,1-0.2
	c0.1,0.1,0.2,0.3,0.2,0.4c-0.5,0.5-0.6,1.2-0.4,1.9c0.4,0.9,1.4,1.4,2.4,1c0.9-0.4,1.5-1.2,1.6-2.4c0.1-1.3-0.3-3-1.8-4
	c-0.8-0.5-2.4-1.2-4.8,0c-3.1,1.6-3.5,7.6-2,12.4c1.7,5.6,5.4,8.8,10.1,8.8c0.4,0,0.8,0,1.2-0.1c0,0,0,0,0.1,0
	c3.5-0.5,6-2.4,7.3-5.5c1-2.4,1-5,1-6.7c0-0.9-0.1-1.8-0.2-2.7c1,1.4,2.5,2.3,4.2,2.3c0.2,0,0.4,0,0.7,0c1.9-0.3,3.2-1.3,3.9-2.9
	c0.5-1.2,0.5-2.6,0.5-3.4c0-3.4-1.8-6.8-3.9-10.6c-1-1.8-1.7-4.2-2-5.2c-0.5-1.9,0-3.7,1.6-5.9c0.3-0.4,0.6-0.9,0.9-1.3
	c0.5-0.7,0.8-1.5,0.9-2.5c0,0,0,0,0.1,0c1,0.7,2,1.4,4.5,2c1.4,0.3,3.5,2.5,3.9,3.4l0.1,0.2c0.2,0.5,0.7,1.4,0.9,2.3
	c0,0.5,0.1,1,0.1,1.4c0,0.7,0,1.2,0,1.5l0,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1l0,0.1c0,0,0,1.5,0,2.1c0,1-0.4,1.2-0.5,1.3c0,0,0,0-0.2,0
	c0,0,0,0-0.1,0c0-0.7-0.2-2.1-1-2.9c-0.3-0.3-0.7-0.5-1.2-0.5c-1,0-1.6,0.9-1.6,1.8c0,1.2,1,2.3,2.4,2.7c0,0.2,0,0.4,0,0.6
	c-0.3-0.3-0.7-0.5-1.2-0.5c-1.5,0-2.5,1.8-2.5,3.4c0,1.7,0.9,2.8,2.1,2.8c0.6,0,1.1-0.2,1.5-0.6c1.2-1.2,1.5-4,1.6-5.5
	c0.2,0,0.4,0,0.5,0c0.4-0.1,1.7-0.7,2-2.1c0.2-0.9,0.1-2.7,0.1-2.8l0-0.2c0-0.3,0-0.8,0-1.5c0-0.6,0.1-1.3,0.2-2
	c0.2-0.8,0.5-1.5,0.7-1.9c0.4-0.9,2.5-3.1,3.9-3.4c2.5-0.6,3.5-1.3,4.5-2c0,0,0,0,0.1,0c0.1,1,0.4,1.7,0.9,2.5
	c0.3,0.4,0.6,0.9,0.9,1.3c1.6,2.2,2.1,4,1.6,5.9c-0.3,1-0.9,3.4-2,5.2c-2.2,3.8-4,7.2-4,10.6c0,0.9,0,2.2,0.5,3.4
	c0.7,1.7,2,2.7,3.9,3c0.2,0,0.4,0,0.7,0h0h0c1.7,0,3.2-0.8,4.2-2.3c-0.1,0.9-0.2,1.8-0.2,2.7c0,1.7,0,4.3,1,6.7
	c1.2,3.1,3.7,5,7.3,5.5c0,0,0,0,0.1,0c0.4,0,0.8,0.1,1.2,0.1c4.7,0,8.4-3.2,10.1-8.8C75.6,66.6,75.2,60.6,72.1,59z M35.6,36.9
	c-1.3-1.2-3-1.9-4.9-2.2c-0.9-0.2-2.7-1.1-3.7-2.4c-0.5-0.7-0.7-1.6-0.8-2.5c-0.1-0.5-0.2-0.9-0.3-1.3c-0.2-0.7-1-1-1.6-0.8
	c-0.7,0.2-1,1-0.8,1.6c0.8,2.3,1.5,6.1,0.6,7.3c-0.3,0.4-0.5,0.8-0.8,1.2l0,0c-0.2,0.3-0.4,0.5-0.6,0.8c-1.6,2.1-3.8,4.9-3,7.8
	c0.2,0.9,1,2.1,1.9,3.4c0.5,0.7,0.9,1.5,1.4,2.2c0.4,0.7,0.8,1.4,1.3,2.1c1.5,2.5,2.9,4.8,2.9,6.9c0,2.3-0.3,3.5-2.1,3.8
	c-1.8,0.2-2.7-1.2-3.1-2.4c-0.4-1.2-0.5-2.3-0.4-3c0,0,0,0,0,0c0.3,0.6,1,1,1.7,0.7c0.6-0.2,1-0.8,1.1-1.5c0.1-0.7-0.2-1.8-1-2.3
	c-0.3-0.2-0.7-0.4-1.3-0.4c-0.5,0-0.9,0.1-1.4,0.4c-0.9,0.5-1.4,1.6-1.6,2.9c-1.5-4.2-3.8-8.4-6.3-12.7c-2.2-3.8-3.5-8.7-4.1-10.9
	c-1.3-4.7,0.4-8.7,3.4-12.8c0.7-0.9,1.3-1.8,1.9-2.6c1.2-1.7,1.6-3.3,1.8-6.1c2.8,1.8,4.4,3.5,10.3,4.9c3.1,0.7,7.6,5.3,8.6,7.4
	c0.8,1.8,1.2,5.5,1.3,8.3C35.6,35.5,35.6,36.3,35.6,36.9z M32.9,56.2c-0.4,0-0.7-0.6-0.7-1.5c0-1,0.5-2,1.1-2c0.1,0,0.2,0.1,0.2,0.1
	c0.1,0.2,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3-0.1C34.1,55,33.6,56.2,32.9,56.2z M56.1,59.3c-0.1-1.3-0.6-2.5-1.6-3
	c-0.5-0.3-1-0.4-1.4-0.4c-0.6,0-1,0.2-1.3,0.4c-0.8,0.6-1.1,1.6-1,2.3c0.1,0.7,0.5,1.3,1,1.5c0.7,0.3,1.4-0.1,1.7-0.7c0,0,0,0,0,0
	c0.1,0.7,0.1,1.8-0.4,3c-0.3,0.9-1.1,2.4-2.8,2.4c-0.1,0-0.2,0-0.3,0c-1.8-0.3-2.1-1.5-2.1-3.8c0-2.1,1.4-4.5,2.9-6.9
	c0.4-0.7,0.9-1.4,1.3-2.1c0.4-0.7,0.9-1.5,1.4-2.2c0.8-1.3,1.6-2.5,1.9-3.4c0.8-2.9-1.4-5.8-3-7.8c-0.2-0.3-0.4-0.6-0.7-0.9
	c-0.3-0.4-0.6-0.8-0.8-1.2c-0.9-1.3-0.1-5,0.6-7.3c0.2-0.7-0.1-1.4-0.8-1.6c-0.7-0.2-1.4,0.1-1.6,0.8c-0.1,0.4-0.2,0.8-0.3,1.3
	c-0.2,0.8-0.4,1.8-0.8,2.5c-0.9,1.4-2.7,2.2-3.6,2.4c-2,0.3-3.6,1.1-5,2.2c0-0.6,0-1.4,0-2.2c0.1-2.9,0.5-6.6,1.3-8.3
	c1-2.1,5.5-6.7,8.6-7.4c5.9-1.4,7.5-3,10.3-4.8c0.1,2.8,0.6,4.4,1.8,6.1c0.6,0.9,1.2,1.7,1.9,2.6c3,4.1,4.6,8.2,3.4,12.8
	c-0.6,2.2-2,7.1-4.1,10.9C60,50.9,57.6,55.1,56.1,59.3z"/>

</svg>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.thelogosmall {
  position: absolute;
  height:90%;
  top:5%;
}
#thelogosmall {
  height: 100%;
  
}
.thelogo__svg {
  width: 100%;
  height: 100%;
}
.thelogosmall__color {
  fill: $main-color-3;
  // ##743434
  .isblack .thelogosmall & {
    fill: black;
  }
}
</style>
