<template>
  <div class="usersettings">
    <div class="usersettings__overlay">
      <div class="usersettings__header">
        <div class="usersettings__topbox">
          <div class="usersettings__hl">
            User Settings
          </div>
          <div class="usersettings__closeoverlay">
            <AppButtonCloseDefault @click="closeController" ref="closeBtn" />
          </div>
        </div>
      </div>

      <div class="usersettings__midbox">
        <AppSearchBoxSmall
          :sbsmallPlaceholder="'Search for'"
          :setCloseTarget="'setUserState'"
        />
      </div>
      <div class="usersettings__btn">
        <AppButton> Click a</AppButton>
      </div>
      <div class="usersettings__btn">
        <AppButton> Click b</AppButton>
      </div>
      <div class="usersettings__btn">
        <AppButton> Click c</AppButton>
      </div>
    </div>
    <div class="usersettings__results">
      <!-- todo: Implement searchResultsComponent  -->
    </div>
  </div>
</template>

<script>
import gsap from "gsap";

import AppButton from "@/components/ui/buttons/AppButton";
import AppButtonCloseDefault from "@/components/ui/buttons/AppButtonCloseDefault";
import AppSearchBoxSmall from "@/components/common/searchox/AppSearchBoxSmall";

import { useStore } from "vuex";
import { reactive, ref, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  components: { AppButtonCloseDefault, AppButton, AppSearchBoxSmall },
  setup() {
    const store = useStore();
    const state = reactive({
      inputText: "",
      usersettingsPlaceholder: "Search for Topics",
    });
    const usersettingsInput = ref(null);
    const isSearching = ref(false);
    const closeBtn = ref(false);
    const isOver = ref(false);

    function testFocus() {
      isSearching.value = true;
    }
    function submite() {}

    function closeController() {
      store.commit("setUserSettingsOpen", false);
    }

    function closeSearch() {
      isSearching.value = false;
    }
    function focusInput() {
      usersettingsInput.value.focus();
    }

    function openAnimation() {
      let ease1 = "power4.out";

      gsap.to(".usersettings__bg", {
        duration: 0.5,
        ease: ease1,
        x: 38,
      });
      gsap.to(".usersettings__input", {
        duration: 0.5,
        ease: ease1,
        x: 14,
        rotation: 0.02,
      });
      gsap.to(".usersettings__icon", {
        duration: 0.5,
        ease: ease1,
        x: -10,
        rotation: 0.02,
      });
    }
    function closeAnimation() {
      let ease1 = "power4.out";
      let itemArray = [
        ".usersettings__bg",
        ".usersettings__input",
        ".usersettings__icon",
      ];
      gsap.killTweensOf(itemArray);

      gsap.to(".usersettings__bg", {
        duration: 0.5,
        ease: ease1,
        x: 0,
      });
      gsap.to(".usersettings__input", {
        duration: 0.5,
        ease: ease1,
        x: 0,
        rotation: 0.02,
      });
      gsap.to(".usersettings__icon", {
        duration: 0.5,
        ease: ease1,
        x: 0,
        rotation: 0.02,
      });
    }

    function onClick() {
      isSearching.value = true;
    }

    watch(isSearching, () => {
      if (isSearching.value) {
        openAnimation();
      }
      if (!isSearching.value) {
        closeAnimation();
      }
    });

    return {
      ...toRefs(state),
      submite,
      onClick,
      isSearching,
      usersettingsInput,
      closeBtn,
      testFocus,
      closeController,
      closeSearch,
      focusInput,
      store,
      isOver,
    };
  },
};
</script>

<style lang="scss">
.usersettings {
  position: relative;
  height: 100%;
  width: 100%;

  padding: 4px;
  padding-right: 0;
  box-sizing: border-box;
  @media screen and (min-width: $medium) {
  }
}
.usersettings__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.usersettings__form {
  width: 100%;
  box-sizing: border-box;

  color: $main-color-1;
  margin-bottom: 16px;
}

.usersettings__topbox {
  display: flex;
  min-height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;

  padding: 0 12px;
  margin-bottom: 16px;
}
.usersettings__midbox {
  display: block;

  padding: 0 12px;
}

.usersettings__hl {
  font-family: $font-family-default;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  max-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* or 133% */

  display: flex;
  align-items: center;

  color: #201e1e;

  padding-left: 12px;

  flex-grow: 1;
}

.usersettings__icon {
  position: relative;
  z-index: 1;
  padding-left: 4px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  max-width: 30px;
}
.usersettings__iconitem {
  cursor: pointer;
  transition: color 0.2s;
  &.isOver {
    color: $main-color-2;
  }
}

.usersettings__inputwrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  border-radius: 50px;
  overflow: hidden;

  outline: none;
  border: none;
  padding: 6px;
  padding-right: 0;
  box-sizing: border-box;
}
.usersettings__input {
  position: relative;
  padding-right: 36px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: unset;
  outline: none;
  border: none;
  color: $main-color-1;

  padding-left: 4px;

  font-family: $font-family-default;

  font-size: 13px;
  line-height: 18px;

  box-sizing: border-box;
  border-radius: 4px;
  &::placeholder {
    margin-left: 6px;
    color: $main-color-1;
    opacity: 0.7;
  }

  &:focus {
    transition: background-color 1s;
  }
}
.usersettings__closeoverlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usersettings__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.usersettings__bg {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: block;

  background-color: rgba($color: #c4c4c4, $alpha: 0.3);
  border-radius: 16px;
  z-index: 0;
}
.usersettings__header {
  position: relative;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.usersettings__results {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px;
  box-sizing: border-box;
}

.usersettings__btn {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
}
</style>
