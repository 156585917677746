<template>
  <div class="sbsmall">
    <form @submit.prevent="submite()" class="sbsmall__form">
      <div class="sbsmall__inputwrapper">
        <div class="sbsmall__bg"></div>
        <div class="sbsmall__icon no-touch-out ">
          <div
            v-if="isSearching"
            :class="{ isOver: isOver }"
            @mouseenter="isOver = true"
            @mouseleave="isOver = false"
            class="sbsmall__iconitem material-icons-round no-touch-out "
            translate="no"
            @click.prevent="closeSearch"
          >
            arrow_backward
          </div>
          <div
            v-else
            :class="{ isOver: isOver }"
            @mouseenter="isOver = true"
            @mouseleave="isOver = false"
            class="sbsmall__iconitem material-icons-round no-touch-out "
            translate="no"
            @click.prevent="focusInput"
          >
            search
          </div>
        </div>
        <input
          class="sbsmall__input"
          type="text"
          ref="sbsmallInput"
          :placeholder="sbsmallPlaceholder"
          v-model="inputText"
          @focus="testFocus"
        />
      </div>
    </form>
  </div>
</template>

<script>
import gsap from "gsap";

import { useStore } from "vuex";
import { reactive, ref, toRefs } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

export default {
  props: {
    sbsmallPlaceholder: String,
    setCloseTarget: String,
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      inputText: "",
      sbsmallPlaceholder: props.sbsmallPlaceholder,
    });
    const sbsmallInput = ref(null);
    const isSearching = ref(false);
    const closeBtn = ref(false);
    const isOver = ref(false);

    function testFocus() {
      isSearching.value = true;
    }
    function submite() {}

    function closeController() {
      store.commit(props.setCloseTarget, false);
    }

    function closeSearch() {
      isSearching.value = false;
    }
    function focusInput() {
      sbsmallInput.value.focus();
    }

    function openAnimation() {
      let ease1 = "power4.out";

      gsap.to(".sbsmall__bg", {
        duration: 0.5,
        ease: ease1,
        x: 38,
      });
      gsap.to(".sbsmall__input", {
        duration: 0.5,
        ease: ease1,
        x: 14,
        rotation: 0.02,
      });
      gsap.to(".sbsmall__icon", {
        duration: 0.5,
        ease: ease1,
        x: -10,
        rotation: 0.02,
      });
    }
    function closeAnimation() {
      let ease1 = "power4.out";
      let itemArray = [".sbsmall__bg", ".sbsmall__input", ".sbsmall__icon"];
      gsap.killTweensOf(itemArray);

      gsap.to(".sbsmall__bg", {
        duration: 0.5,
        ease: ease1,
        x: 0,
      });
      gsap.to(".sbsmall__input", {
        duration: 0.5,
        ease: ease1,
        x: 0,
        rotation: 0.02,
      });
      gsap.to(".sbsmall__icon", {
        duration: 0.5,
        ease: ease1,
        x: 0,
        rotation: 0.02,
      });
    }

    function onClick() {
      isSearching.value = true;
    }

    watch(isSearching, () => {
      if (isSearching.value) {
        openAnimation();
      }
      if (!isSearching.value) {
        closeAnimation();
      }
    });

    return {
      ...toRefs(state),
      submite,
      onClick,
      isSearching,
      sbsmallInput,
      closeBtn,
      testFocus,
      closeController,
      closeSearch,
      focusInput,
      store,
      isOver,
    };
  },
};
</script>

<style lang="scss">
.sbsmall {
  position: relative;
  height: 100%;
  width: 100%;
}
.sbsmall__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sbsmall__form {
  width: 100%;
  box-sizing: border-box;

  margin-bottom: 16px;

  color: $main-color-1;
}

.sbsmall__topbox {
  display: flex;
  min-height: 72px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;

  padding: 0 12px;
  margin-bottom: 16px;
}
.sbsmall__midbox {
  display: block;

  padding: 0 12px;
}

.sbsmall__hl {
  font-family: $font-family-default;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 22px;
  max-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* or 133% */

  display: flex;
  align-items: center;

  color: #201e1e;

  padding-left: 12px;

  flex-grow: 1;
}

.sbsmall__icon {
  position: relative;
  z-index: 1;
  padding-left: 4px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  max-width: 30px;
}
.sbsmall__iconitem {
  cursor: pointer;
  transition: color 0.2s;
  &.isOver {
    color: $main-color-2;
  }
}

.sbsmall__inputwrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 36px;
  border-radius: 50px;
  overflow: hidden;

  outline: none;
  border: none;
  padding: 6px;
  padding-right: 0;
  box-sizing: border-box;
}
.sbsmall__input {
  position: relative;
  padding-right: 36px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: unset;
  outline: none;
  border: none;
  color: $main-color-1;

  padding-left: 4px;

  font-family: $font-family-default;

  font-size: 13px;
  line-height: 18px;

  box-sizing: border-box;
  border-radius: 4px;
  &::placeholder {
    margin-left: 6px;
    color: $main-color-1;
    opacity: 0.7;
  }

  &:focus {
    transition: background-color 1s;
  }
}
.sbsmall__closeoverlay {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sbsmall__overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.sbsmall__bg {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: block;

  background-color: rgba($color: #c4c4c4, $alpha: 0.3);
  border-radius: 16px;
  z-index: 0;
}
.sbsmall__header {
  position: relative;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.sbsmall__results {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 12px;
  box-sizing: border-box;
}

.sbsmall__btn {
  margin-left: 0;
  position: relative;
  z-index: 200;
}

.sbsmall__btn {
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
}
</style>
