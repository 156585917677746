<template>
  <button @click.prevent="closeOverlay" class="closeoverlay">
    <div class="abco__icon">
      <span class="s_1"></span>
      <span class="s_2"></span>
    </div>
  </button>
</template>

<script>
import overlayHandling from "@/utilities/composition/overlay/overlayHandling";
export default {
  setup() {
    const { closeOverlay } = overlayHandling();

    return {
      closeOverlay,
    };
  },
};
</script>

<style lang="scss">
.closeoverlay {
  cursor: pointer;
  background-color: unset;
  display: flex;
  justify-content: center;
  align-items: center;

  outline: none;
  border: none;

  padding: 0;

  width: 36px;
  height: 30px;

  &:hover {
    .abco__icon {
      transform: rotate(135deg) scale(1.1);
      color: $main-color-5;
    }
    .abco__text {
      color: $main-color-5;
    }
  }

  @media screen and (min-width: $medium) {
  }
}
.abco__icon {
  transform: rotate(45deg) scale(0.7);
  width: 35px;
  height: 2px;
  transition: 0.2s;
  display: block;

  @media screen and (min-width: $medium) {
    transform: rotate(45deg) scale(1);
  }
}
.abco__text {
  padding-left: 10px;
}

.abco__icon {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  transition: all 200ms ease-in-out;
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.abco__icon span {
  position: absolute;
  width: 35px;
  height: 2px;
  background-color: $main-color-2;
  transition: all 200ms;
}

.s_1 {
  top: 13px;
  left: 0;
  transform: rotate(-90deg);
}

.s_2 {
  top: 14px;
  left: 0;
}
</style>
