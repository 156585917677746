<template>
  <div class="appclosebtn material-icons-round" translate="no">
    close
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style lang="scss" scoped>
.appclosebtn {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 100px;

  transition: 0.2s;

  &--close {
    height: auto;
    transition: 0.2s;
  }

  &:hover {
    background: none;
    background: $main-color-5;
    color: white;
    transform: rotate(180deg) scale3d(1.1, 1.1, 1);
  }
}
</style>
